// ---------------- External imports ------------------ //
import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'

// ---------------- Material-UI imports ------------------ //
import { Button, Modal } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { LoadingButton } from '@mui/lab'

// ---------------- Local imports ------------------ //
import styles from './DeleteDeviceContainer.module.scss'
import { chatarrizarDevice, checkChatarrizarDevice } from 'api/system'

const DeleteDeviceContainer = props => {
  const [open] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { hideModal, translate, system, showSnackbar } = props
  const { MacAddressId, _id } = system

  const StyledPaper = withStyles({
    root: {
      maxHeight: '100% !important',
      'overflow-y': 'visible',
      fontFamily: 'Roboto',
      verticalAlign: 'top',
      maxWidth: '500px'
    }
  })

  const handleSubmit = async () => {
    const handleBeforeUnload = event => {
      event.preventDefault()
      event.returnValue = '' // This is required for Chrome to show the warning dialog
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    setIsSubmitting(true)
    const response = await chatarrizarDevice(_id)
    if (response.message === 'ok') {
      let check = await checkChatarrizarDevice(_id)
      // Check body message attribute (processing/done)
      while (check.message === 'processing') {
        // Check every 5 seconds
        await new Promise(resolve => setTimeout(resolve, 5000))
        check = await checkChatarrizarDevice(_id)
      }
      setIsSubmitting(false)
    }
    window.removeEventListener('beforeunload', handleBeforeUnload)
    hideModal()
    showSnackbar('Dispositivo chatarrizado correctamente')
  }

  const handleClose = () => {
    hideModal()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
        BackdropProps={{
          classes: {
            root: styles.backdrop
          }
        }}
        PaperComponent={StyledPaper}
      >
        <div className={styles.modalContainer}>
          <div className={styles.header}>
            <h2>{translate('deleteDevice')}</h2>
          </div>
          <div className={styles.content}>
            <p>{translate('deleteDeviceInfo')}</p>
            <br />
            <p>
              {translate('confirmDeleteDevice') + ': '} <br />{' '}
              <span className={styles.deviceID}>{MacAddressId}</span>
            </p>
          </div>

          <div className={styles.buttonsWrapper}>
            <Button
              onClick={handleClose}
              disabled={isSubmitting}
              className={styles.buttonCancelar}
            >
              {translate('cancel')}
            </Button>
            <LoadingButton
              disableRipple
              className={styles.submitButton}
              loading={isSubmitting}
              variant='contained'
              loadingPosition='end'
              disabled={isSubmitting}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            >
              {translate('delete')}
            </LoadingButton>
          </div>
        </div>
      </Modal>
    </div>
  )
}

DeleteDeviceContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(DeleteDeviceContainer)
