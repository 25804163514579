import request from 'utils/request'

export function loadSystems(data) {
  return request('systems/admin/devices', {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function loadLastVersions() {
  return request('systems/admin/lastversions', {
    method: 'GET'
  }).then(([body]) => body)
}

export function loadSystem(deviceId) {
  return request(`systems/admin/devices/${deviceId}`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function activateSystemApiKey(deviceId) {
  return request(`systems/admin/apikey/${deviceId}`, {
    method: 'PUT'
  }).then(([body]) => body)
}

export function disableSystemApiKey(deviceId) {
  return request(`systems/admin/apikey/disable/${deviceId}`, {
    method: 'PUT'
  }).then(([body]) => body)
}

export function updateResetSystem(data, deviceId) {
  return request(`systems/admin/reset/${deviceId}`, {
    method: 'PUT',
    data: data
  }).then(([body]) => body)
}

export function updateNextVersionSystem(data, deviceId) {
  return request(`systems/admin/version/${deviceId}`, {
    method: 'PUT',
    data: data
  }).then(([body]) => body)
}

export function createSystem(data) {
  return request('systems', {
    method: 'POST',
    data: data
  }).then(([body]) => body)
}

export function getSystemMeasurementsByType(id, type) {
  return request(`systems/${id}/measurements-by-type/${type}`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function getSystemMeasurementsByTypeNew(data) {
  return request(`systems/mtype`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function updateSystem(data) {
  return request('systems', {
    method: 'PUT',
    data: data
  }).then(([body]) => body)
}

export function deleteSystem(id) {
  return request(`systems/admin/delsystem/system/${id}`, {
    method: 'DELETE'
  })
}

export function deleteSystemData(id) {
  return request(`systems/admin/delsystem/data/${id}`, {
    method: 'DELETE'
  })
}

export function deleteSystemFromUser(id, CustomerId) {
  return request(`users/${CustomerId}/system/${id}`, {
    method: 'DELETE'
  })
}

export function syncAirtableInformation() {
  return request(`systems/admin/airtable`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function chatarrizarDevice(id) {
  return request(`systems/admin/chatarrizar/${id}`, {
    method: 'POST'
  }).then(([body]) => body)
}

export function checkChatarrizarDevice(id) {
  return request(`systems/admin/chatarrizar/${id}`, {
    method: 'GET'
  }).then(([body]) => body)
}
