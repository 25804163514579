import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { hideModal } from 'common/actions/modal'
import Modals from 'modules/models'
import { getModalType, getModalProps } from 'common/selectors/modal'
import * as ModalTypes from '../../constants/ModalTypes'

const MODAL_COMPONENT = {
  [ModalTypes.VERSION_MODAL]: Modals.VersionModal,
  [ModalTypes.DOWNLOAD_SETTINGS]: Modals.DownloadSettings,
  [ModalTypes.CONFIRMATION_MODAL]: Modals.ConfirmationModal,
  [ModalTypes.DELETE_DEVICE]: Modals.DeleteDevice
}

export class ModalRoot extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    props: PropTypes.object,
    hideModal: PropTypes.func.isRequired
  }

  render() {
    const { type, props, hideModal } = this.props
    if (!type) return null

    const ModalComponent = MODAL_COMPONENT[type]

    return (
      <ModalComponent
        {...props}
        hideModal={hideModal}
      />
    )
  }
}

const mapStateToProps = state => ({
  type: getModalType(state),
  props: getModalProps(state)
})

const mapActionsToProps = {
  hideModal
}

export default connect(mapStateToProps, mapActionsToProps)(ModalRoot)
